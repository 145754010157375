export const environment = {
  production: true,
  firebase:{
    apiKey: "AIzaSyAButti9zsRP5UiyyA0lASbgpZpdWd2FEI",
    authDomain: "newletters-41438.firebaseapp.com",
    databaseURL: "https://newletters-41438.firebaseio.com",
    projectId: "newletters-41438",
    storageBucket: "newletters-41438.appspot.com",
    messagingSenderId: "1045649760313",
    appId: "1:1045649760313:web:cb34b963adba43455eede8",
    measurementId: "G-QZ77S4PSX7"
    },
    urlStatic: 'https://www.wediom.com/api/',
    path_prod: 'https://www.wediom.com/api/',
    path_storage: 'https://www.wediom.com/',
    path_token: 'APP00000000'
   
};
