<div class=" w-100" style="background-color: rgb(57, 55, 177); border-radius: 0.5rem;">
            <div class="modal-content" style=" border-radius: 0.5rem;">
                <div class="modal-header text-white" style="background-color: rgb(57, 55, 177); border-radius: 0.5rem 0.5rem 0 0; position: relative;">
                    <h3 class="modal-title text-center"><strong class="text-center">{{head_metod | uppercase}}</strong> </h3>
                    <span style="position: absolute; right: 1%; top: 1%;">{{param_prc_prcs_10 | uppercase}} </span>
                </div>
                <div class="modal-body">
                    <div *ngIf="metodo === 'FNC'" class="container-fluid">
                        <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div class="row">
                                <div class="col-md-12  p-1">
                                    <h3><strong>Definición: " {{data.data.val_def | uppercase}}  " </strong></h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro titular</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Parametro principal</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_1" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Tipo</mat-label>
                                        <mat-select formControlName="op_2" autocomplete="off">
                                            <mat-option *ngFor="let i of data_func" [value]="i.concepto_definicion">{{i.descrip_definicion | uppercase}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Parametro secundario 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_4" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Parametro secundario 2</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_5" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Parametro secundario 3</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_6" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="metodo === 'PRC'" class="container-fluid">
                        <form *ngIf="oper_met === 'AVATAR'" class="row" [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div class="col-md-12">
                                <div class="row py-0">
                                    <div class="col-md-6 col-sm-6 col-xs-6 py-2 d-flex justify-content-center">
                                        <div class="form-group">
                                            <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6 py-1 d-flex justify-content-center">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Tipo avatar</mat-label>
                                                <mat-select formControlName="nivel_img" autocomplete="off" (selectionChange)="listarParametroGaleriaOperacional($event.value)">
                                                    <mat-option *ngFor="let i of sub_param" [value]="i.nivel_img">{{i.des_definicion}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                    </div>
                                </div>
                                <div class="row py-0">
                                    <!-- <div *ngFor="let i of val_galeria" class="col-md-4 py-0 d-flex justify-content-center gap-1 mb-2">
                                        <div  class="card" style="background-color: rgba(255, 0, 0, 0) !important;">
                                                <div class="card-body">
                                                    <img [src]="i.val_def" style="aspect-ratio: 1/1; width:100px; object-fit: 100% 100%; border-radius: 1rem;" >
                                                </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 d-flex justify-content-center">
                                        <div id="carouselExampleControls" class="carousel slide  w-100" data-ride="carousel">
                                            <div class="carousel-inner">
                                              <div *ngFor="let i of val_galeria; let k = index" [class.active]="k === 0" class="carousel-item text-center">
                                                  <img class="mx-auto d-block rounded"  [src]="i.val_def"   style="aspect-ratio: 1/1;  width:250px; object-fit: 100% 100%;">
                                              </div>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                                                <span class="" aria-hidden="true"><i class="fa-solid fa-arrow-left fa-xl"></i></span>
                                                <span class="sr-only">Previous</span>
                                              </button>
                                              <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                                                <span class="" aria-hidden="true"><i class="fa-solid fa-arrow-right fa-xl"></i></span>
                                                <span class="sr-only">Next</span>
                                              </button>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="oper_met === 'STRIPE'" class="row" [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div class="col-md-6">
                                <div class="row py-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                        <div class="form-group">
                                            <label for="img">Logo</label>
                                            <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                        </div>
                                    </div>
                                </div>
                                <div class="row py-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                        <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro key 1</mat-label>
                                            <textarea matInput style="font-size: 12px; color: black;" formControlName="op_1" autocomplete="off"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro key 2</mat-label>
                                            <textarea matInput style="font-size: 12px; color: black;" formControlName="op_2" autocomplete="off"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro correo</mat-label>
                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="oper_met === 'PAYPAL'" class="row" [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div class="col-md-6">
                                <div class="row py-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                        <div class="form-group">
                                            <label for="img">Logo</label>
                                            <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                        </div>
                                    </div>
                                </div>
                                <div class="row py-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                        <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro key 1</mat-label>
                                            <textarea matInput style="font-size: 12px; color: black;" formControlName="op_1" autocomplete="off"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro key 2</mat-label>
                                            <textarea matInput style="font-size: 12px; color: black;" formControlName="op_2" autocomplete="off"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-12  p-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parametro correo</mat-label>
                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="oper_met === 'SMTP'" [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Descripción</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_1">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_2">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Host</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_3">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Puerto</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_4">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Usuario</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_5">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Contraseña</mat-label>
                                                <input matInput  autocomplete="off" formControlName="op_6">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Ssl</mat-label>
                                                <mat-select formControlName="op_7" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="op_8" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="oper_met === 'MEMBRESIA'" [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_1" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Valor 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_2" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Valor 2</mat-label>
                                        <input class="text-right" id="op_3_m"  placeholder="0,00" matInput (keyup)="format($event,'op_3_m','tasa')" style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro 2</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_4" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Valor 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_5" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Valor 2</mat-label>
                                        <input class="text-right" id="op_6_m" placeholder="0,00" matInput (keyup)="format($event,'op_6_m','tasa')" style="font-size: 12px; color: black;" formControlName="op_6" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro 3</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_7" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Valor 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_8" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Valor 2</mat-label>
                                        <input class="text-right" id="op_9_m" placeholder="0,00" matInput (keyup)="format($event,'op_9_m','tasa')" style="font-size: 12px; color: black;" formControlName="op_9" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro 4</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_10" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Valor 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_11" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3  p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Valor 2</mat-label>
                                        <input class="text-right" id="op_12_m" placeholder="0,00" matInput (keyup)="format($event,'op_12_m','tasa')" style="font-size: 12px; color: black;" formControlName="op_12" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <form *ngIf="oper_met === 'INICIO'" [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div *ngIf="tabla" class="row">
                                <div class="col-md-4 p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Parametro 1</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="val_def" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Descripción</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" formControlName="val_cat" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 p-1">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Estatus</mat-label>
                                        <mat-select formControlName="estatus" autocomplete="off">
                                            <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2" style="position: relative;">
                                    <button  [disabled]="forma.invalid" style="position: absolute; right: 1%; top: 3%;" (click)="modalrespuesta(crear)" matTooltip="AGREGAR"  class="mr-1 my-2 btn bg-warning text-white"><mat-icon >add</mat-icon></button>
                                    <button *ngIf="operacion === actualizar" [disabled]="forma.invalid" style="position: absolute; right: 35%; top: 3%;" (click)="modalrespuesta(actualizar)" matTooltip="EDITAR PARAMETRO"  class="mr-1 my-2 btn bg-primary text-white"><mat-icon >edit</mat-icon></button>
                                </div>
                            </div>
                            <div *ngIf="tabla" class="row modalapp" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                                <div class=" table-responsive  h-100" >
                                    <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">
                        
                                        <ng-container matColumnDef="cod_param_1" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Id</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.id_relacion_definicion_app}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="val_def" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Parametro</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_def}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="des_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Descripción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_cat | uppercase}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="fecha_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Fecha</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="cod_img" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Img</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;">
                                                <div  class="col-md-12 d-flex justify-content-center">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-center">
                                                            <form *ngIf="element.cod_img === null" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img($event)"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="element.cod_img !== null" [src]="element.cod_img" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                        <div class="col-md-2 d-flex justify-content-end py-1">
                                                            <button   class=" bg-warn mx-1"   matTooltip="AGREGAR LOGO" mat-mini-fab (click)='getImgConfig(element)'>
                                                                <mat-icon >edit</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estatus" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Estatus</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="hora_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Hora</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="procesos">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                                <h5><strong>Acción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;" class="text-center col-2" >
                                                <button   class=" bg-success mr-2" (click)="cargarEscalaParam(element)"   matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >build</mat-icon>
                                                </button>
                                                <button   class=" bg-primary mr-2" (click)="editarParametro(element)"  matTooltip="EDITAR PARAMETROS" mat-mini-fab >
                                                    <mat-icon >edit</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                    </table>
                                    <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="!tabla" class="row">
                                <div class="col-md-12 container">
                                    <div class="row">
                                        <form class="container"  [formGroup]="formaTarea" (keydown.enter)="$event.preventDefault()">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h3><strong>Definición: {{des_tarea | uppercase}} </strong></h3>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Tipo</mat-label>
                                                        <mat-select formControlName="op_1" autocomplete="off" (selectionChange)="editarDinamicasForma($event.value)">
                                                            <mat-option *ngFor="let i of sub_param" [value]="i.nivel_sub">{{i.des_definicion | uppercase}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Parametro titular</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="op_2" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 d-flex justify-content-end" style="position: relative;">
                                                    <button [disabled]="formaTarea.invalid" matTooltip="GUARDAR" style="position: absolute; top: 1%; right: 1%;" (click)="modalrespuesta()"  class="btn bg-primary text-white"><mat-icon >save</mat-icon></button>
                                                </div>
                                            </div>
                                            <hr>
                                            <div *ngIf="tipo_tarea === 0">
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 1</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 2</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_4" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 3</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_5" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 4</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_6" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 5</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_7" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 6</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_8" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 7</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_9" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 8</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_10" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 9</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_11" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 ">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 10</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_12" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="tipo_tarea === 1">
                                                <div class="row">
                                                    <div class="col-md-6  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro principal</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_3" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro operacional</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_4" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 1</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_5" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 2</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_6" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 3</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_7" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 4</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_8" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 5</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_9" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4  p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Parametro secundario 6</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="op_10" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="tipo_tarea === 2">
                                                <div class="row">
                                                    <div class="col-md-4 container">
                                                            <div class="row  w-100">
                                                                <div class="col-md-10 d-flex justify-content-start">
                                                                    <form *ngIf="conten_tarea[4] === null || conten_tarea[4] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                        <input (change)="cargar_img_dinamica($event,'1')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                    </form>
                                                                    <img *ngIf="objectURL_0" [src]="objectURL_0 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[5] === null || conten_tarea[5] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'2')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_1" [src]="objectURL_1 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                </div>
                                                </div>
                                                 <div class="row">
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[6] === null || conten_tarea[6] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'3')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_3" [src]="objectURL_2 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="col-md-4 container">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-start">
                                                            <form *ngIf="conten_tarea[7] === null || conten_tarea[7] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img_dinamica($event,'4')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="objectURL_4" [src]="objectURL_3 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                    </div>
                                            </div>
                                                </div>
                                               <div class="row">
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[8] === null || conten_tarea[8] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'5')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_5" [src]="objectURL_4 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="col-md-4 container">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-start">
                                                            <form *ngIf="conten_tarea[9] === null || conten_tarea[9] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img_dinamica($event,'6')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="objectURL_6" [src]="objectURL_5 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[10] === null || conten_tarea[10] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'7')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_7" [src]="objectURL_6 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[11] === null || conten_tarea[11] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'8')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_8" [src]="objectURL_7 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[12] === null || conten_tarea[12] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'9')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_9" [src]="objectURL_8 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 container">
                                                        <div class="row  w-100">
                                                            <div class="col-md-10 d-flex justify-content-start">
                                                                <form *ngIf="conten_tarea[13] === null || conten_tarea[13] === undefined" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                    <input (change)="cargar_img_dinamica($event,'10')"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                                </form>
                                                                <img *ngIf="objectURL_10" [src]="objectURL_9 | pipeServices" class="mr-2" width="50px" height="50px" class="rounded" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="param_prc_prcs_1 === 2" class="row modalapp" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                                <div class=" table-responsive  h-100" >
                                    <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">
                        
                                        <ng-container matColumnDef="cod_param_1" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Id</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.id_relacion_definicion_app}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="val_def" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Parametro</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_def}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="des_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Descripción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_cat | uppercase}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="fecha_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Fecha</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="cod_img" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Img</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;">
                                                <div  class="col-md-12 d-flex justify-content-center">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-center">
                                                            <form *ngIf="element.cod_img === null" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img($event)"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="element.cod_img !== null" [src]="element.cod_img" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                        <div class="col-md-2 d-flex justify-content-end py-1">
                                                            <button   class=" bg-warn mx-1"   matTooltip="AGREGAR LOGO" mat-mini-fab (click)='getImgConfig(element)'>
                                                                <mat-icon >edit</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estatus" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Estatus</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="hora_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Hora</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="procesos">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                                <h5><strong>Acción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center col-2" >
                                                <button   class=" bg-success mr-2"  (click)="cargarEscalaParam(element)"  matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >build</mat-icon>
                                                </button>
                                                <button   class=" bg-primary mr-2"   matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >edit</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                    </table>
                                    <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="param_prc_prcs_1 === 3" class="row modalapp" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                                <div class=" table-responsive  h-100" >
                                    <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">
                        
                                        <ng-container matColumnDef="cod_param_1" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Id</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.id_relacion_definicion_app}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="val_def" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Parametro</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_def}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="des_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Descripción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_cat | uppercase}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="fecha_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Fecha</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="cod_img" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Img</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;">
                                                <div  class="col-md-12 d-flex justify-content-center">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-center">
                                                            <form *ngIf="element.cod_img === null" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img($event)"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="element.cod_img !== null" [src]="element.cod_img" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                        <div class="col-md-2 d-flex justify-content-end py-1">
                                                            <button   class=" bg-warn mx-1"   matTooltip="AGREGAR LOGO" mat-mini-fab (click)='getImgConfig(element)'>
                                                                <mat-icon >edit</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estatus" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Estatus</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="hora_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Hora</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="procesos">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                                <h5><strong>Acción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center col-2" >
                                                <button   class=" bg-success mr-2" (click)="cargarEscalaParam(element)"   matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >build</mat-icon>
                                                </button>
                                                <button   class=" bg-primary mr-2"   matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >edit</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                    </table>
                                    <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                            <div *ngIf="param_prc_prcs_1 === 4" class="row modalapp" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                                <div class=" table-responsive  h-100" >
                                    <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">
                        
                                        <ng-container matColumnDef="cod_param_1" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Id</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.id_relacion_definicion_app}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="val_def" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Parametro</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_def}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="des_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Descripción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.val_cat | uppercase}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="fecha_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Fecha</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="cod_img" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Img</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;">
                                                <div  class="col-md-12 d-flex justify-content-center">
                                                    <div class="row  w-100">
                                                        <div class="col-md-10 d-flex justify-content-center">
                                                            <form *ngIf="element.cod_img === null" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                                                <input (change)="cargar_img($event)"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                                            </form>
                                                            <img *ngIf="element.cod_img !== null" [src]="element.cod_img" class="mr-2" width="50px" height="50px" class="rounded" >
                                                        </div>
                                                        <div class="col-md-2 d-flex justify-content-end py-1">
                                                            <button   class=" bg-warn mx-1"   matTooltip="AGREGAR LOGO" mat-mini-fab (click)='getImgConfig(element)'>
                                                                <mat-icon >edit</mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estatus" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Estatus</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="hora_inicio" >
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                                <h5><strong>Hora</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                                    
                                        </ng-container>
                                        <ng-container matColumnDef="procesos">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                                <h5><strong>Acción</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center col-2" >
                                                <button   class=" bg-success mr-2"  matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >build</mat-icon>
                                                </button>
                                                <button   class=" bg-primary mr-2"   matTooltip="FUNCIONALES" mat-mini-fab >
                                                    <mat-icon >edit</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                    </table>
                                    <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div> -->
                        </form>
                    </div>
                </div>
                <div class="modal-footer" style="position: relative;">
                    <div *ngIf="param_prc_prcs_2 && param_prc_prcs_1 > 1" class="fs-3 fw-bold" style="position: absolute; left: 1%; top: 1%;">
                        <span>Id parametro padre: {{param_prc_prcs_2}}</span>
                        <span class="mx-3">Escala: {{param_prc_prcs_1}} </span> 
                        <span role="button" matTooltip="Regresar" (click)="volverEscalaParam(param_prc_prcs_1)"><i class="fas fa-sign-out-alt"></i></span>
                    </div>
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()" style=" margin-right: 5px;">Cancelar</button>
                    <button *ngIf="oper_met !== 'INICIO'" mat-button [disabled]="forma.invalid" class=" btn-primary" (click)="modalrespuesta()" style=" margin-right: 5px;">Aceptar</button>
                </div>
            </div>
</div>
