import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { forIn } from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-usuarios',
  templateUrl: './form-usuarios.component.html',
  styleUrls: ['./form-usuarios.component.css']
})
export class FormUsuariosComponent implements OnInit {
rlsr:any;
archivo:File;
hide:any = true;
nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
rol:any;
licencias:any;
unidadp:any=[];
roles:any=[];
gerencia:any;
dpto:any;
cargo:any;
estatus:any;
objectURL:any;
serializedDate = new FormControl((new Date()).toISOString());
xtermino:any = {
  des_gerencia:'',
  des_dpto:''
};
bandera:any = false;
   constructor( 
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private notifi:ToastNotificationService,
    private dialogRef: MatDialogRef<FormUsuariosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));
      console.log(this.data, 'primero');
      this.objectURL = this.atrb.IMAGENES_FIJAS.sin_img_prod;
    this.accion = data.accion;
    this.unidad = data.unidades;
    this.rol = data.roles;
    for (const i in this.unidad) {
      if (parseInt(this.rlsr) <= 1 ) {
        if (this.unidad[i].des_unidad_padre.toLowerCase() === 'general') {
          this.unidad[i].cod_unidad = this.unidad[i].cod_unidad.toString();
          this.unidadp.push(this.unidad[i]);
        }
      }else{
        if (parseInt(this.unidad[i].cod_unidad_padre) === 0 &&  this.unidad[i].des_unidad.toLowerCase() !== 'general') {
          this.unidad[i].cod_unidad = this.unidad[i].cod_unidad.toString();
          this.unidadp.push(this.unidad[i]);
        }
      }
    }
    /////// ceo
      if (parseInt(this.rlsr) <= 0 ) {
        this.licencias = data.licencias;
        for (const i in this.rol) {
            if (parseInt(this.rol[i].cod_usuario) < 3 ) {
              this.rol[i].cod_usuario = this.rol[i].cod_usuario.toString();
              this.roles.push(this.rol[i]);
            }
        }
    }
    ////administrador
    if (parseInt(this.rlsr) > 0 && parseInt(this.rlsr) < 2) {
        this.licencias = data.licencias;
        for (const i in this.rol) {
            if (parseInt(this.rol[i].cod_usuario) === 2) {
              this.rol[i].cod_usuario = this.rol[i].cod_usuario.toString();
              this.roles.push(this.rol[i]);
            }
        }
    }
    /////empresa
    if (parseInt(this.rlsr) === 2 ){
      for (const i in this.rol) {
        if (parseInt(this.rol[i].cod_usuario) === 3) {
          this.rol[i].cod_usuario = this.rol[i].cod_usuario.toString();
          this.roles.push(this.rol[i]);
        }
    }
    }

    if (parseInt(this.rlsr) <= 0) {
      if (data.accion === 'actualizar') {
        this.forma = fb.group({
          'cod_usuario': [data.data.cod_usuario,[Validators.required]],
          'des_usuario': [data.data.nombre,[Validators.required]],
          /////gerencia
          'cod_unidad':[data.data.cod_unidad,[Validators.required]],
          'des_unidad':[data.data.des_unidad],
          ////dpto
          'cod_dpto':[data.data.cod_dpto,[Validators.required]],
          'des_dpto':[data.data.des_dpto],
          ////perfil
          'cod_perfil':[data.data.cod_perfil,[Validators.required]],
          'des_perfil':[data.data.des_perfil],
          //// rol
          'cod_rol':[data.data.cod_rol,[Validators.required]],
          'des_rol':[data.data.des_rol],
          'estatus': [data.data.estatus,[Validators.required]],
          'correo':[data.data.correo,[Validators.required, Validators.email]],
          'direccion':[data.data.direccion,[Validators.required]],
          'telefono': [data.data.telefono,[Validators.required]],
          'nom_usuario': [data.data.nom_usuario,[Validators.required]],
          'pass_usuario': [this.hashkey.decrypt(data.data.pass_usuario),[Validators.required]],
          'mod_usuario': [data.data.mod_usuario,[Validators.required]],
          'edad':'N/A',
          'apellido':'N/A',
          'fecha_nacimiento':'N/A',
          'fecha_ingreso':'N/A',
          'id':'N/A',
          'cod_empresa':[''],
          'cod_app':[''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'img_actual':[data.data.cod_img_sin],
          'operacion': [this.atrb.ACCION_ACTUALIZAR]
        });
      }
      if (data.accion === 'insertar') {
        this.forma = fb.group({
          'cod_usuario': ['',[Validators.required, Validators.pattern(this.atrb.CODREGEX)]],
          'des_usuario': ['',[Validators.required]],
          /////gerencia
          'cod_unidad':['',[Validators.required]],
          'des_unidad':[''],
          ////dpto
          'cod_dpto':['',[Validators.required]],
          'des_dpto':[''],
          ////perfil
          'cod_perfil':['',[Validators.required]],
          'des_perfil':[''],
          //// rol
          'cod_rol':['',[Validators.required]],
          'des_rol':[''],
          'estatus': ['',[Validators.required]],
          'correo':['',[Validators.required, Validators.pattern(this.atrb.EMAILREGEX)]],
          'direccion':['',[Validators.required]],
          'telefono': ['',[Validators.required]],
          'nom_usuario': ['',[Validators.required]],
          'pass_usuario': ['',[Validators.required]],
          'mod_usuario': ['',[Validators.required]],
          'edad':'N/A',
          'apellido':'N/A',
          'fecha_nacimiento':'N/A',
          'fecha_ingreso':'N/A',
          'id':'N/A',
          'cod_empresa':[''],
          'cod_app':[''],
          'archivo':[''],
          'cod_img':[''],
          'formdata':[''],
          'operacion': [this.atrb.ACCION_CREAR]
        });
      }
    }else{
      if (data.accion === 'actualizar') {
        this.forma = fb.group({
          'cod_usuario':[data.data.cod_usuario,[Validators.required]],
          'des_usuario': [data.data.nombre,[Validators.required]],
          /////gerencia
          'cod_unidad':[data.data.cod_unidad,[Validators.required]],
          'des_unidad':[data.data.des_unidad],
          ////dpto
          'cod_dpto':[data.data.cod_dpto,[Validators.required]],
          'des_dpto':[data.data.des_dpto],
          ////perfil
          'cod_perfil':[data.data.cod_perfil,[Validators.required]],
          'des_perfil':[data.data.des_perfil],
          //// rol
          'cod_rol':[data.data.cod_rol,[Validators.required]],
          'des_rol':[data.data.des_rol],
          'estatus': [data.data.estatus,[Validators.required]],
          'correo':[data.data.correo,[Validators.required, Validators.email]],
          'direccion':[data.data.direccion,[Validators.required]],
          'telefono': [data.data.telefono,[Validators.required]],
          'nom_usuario': [data.data.nom_usuario,[Validators.required]],
          'pass_usuario': [this.hashkey.decrypt(data.data.pass_usuario),[Validators.required]],
          'mod_usuario': [''],
          'edad':'N/A',
          'apellido':'N/A',
          'fecha_nacimiento':'N/A',
          'fecha_ingreso':'N/A',
          'id':'N/A',
          'cod_empresa':[''],
          'cod_app':[''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'img_actual':[data.data.cod_img_sin],
          'operacion': this.atrb.ACCION_ACTUALIZAR
        });
      }
      if (data.accion === 'insertar') {
        this.forma = fb.group({
          'cod_usuario': ['',[Validators.required, Validators.pattern(this.atrb.CODREGEX)]],
          'des_usuario': ['',[Validators.required]],
          'estatus': ['',[Validators.required]],
          'correo':['',[Validators.required, Validators.pattern(this.atrb.EMAILREGEX)]],
          'direccion':['',[Validators.required]],
          'telefono':['',[Validators.required]],
          'cod_rol':['',[Validators.required]],
          'des_rol':[''],
          'cod_unidad':['',[Validators.required]],
          'des_unidad':[''],
          'cod_perfil':['',[Validators.required]],
          'des_perfil':[''],
          'cod_dpto':['',[Validators.required]],
          'des_dpto':[''],
          'nom_usuario': ['',[Validators.required]],
          'pass_usuario': ['',[Validators.required]],
          'mod_usuario': [''],
          'edad':'N/A',
          'apellido':'N/A',
          'fecha_nacimiento':'N/A',
          'fecha_ingreso':'N/A',
          'id':'N/A',
          'cod_empresa':[''],
          'cod_app':[''],
          'archivo':[''],
          'cod_img':[''],
          'formdata':[''],
          'operacion': this.atrb.ACCION_CREAR
        });
      }
    }
    }

  ngOnInit(){
    if (this.data.accion.toUpperCase() === this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
      this.listarUnidadUpdate(this.data.data);
      this.objectURL = this.data.data.cod_img;
    }else{
      this.listarUnidad(null);
    }
  }
  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }

  async listarUnidad(xvalor:any){
    try {
      if (this.data.accion.toUpperCase() === this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
        console.log(xvalor,'valores change');
              let dpto;
              let cargo;
              await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
                let xobjeto = {
                  api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUnidades)
                };
                const response = await this.servicio.ApiResthttp(xobjeto).toPromise();
                for (const i in response) {
                  response[i].cod_unidad = response[i].cod_unidad.toString();
                }
                let valor_fill = response.filter( function(elem){
                                return elem.cod_unidad === xvalor.value;
                });
                console.log(valor_fill,'valor codigo');
                this.cargo = [];
                this.estatus = await this.atrb.ESTATUS;
              if (xvalor !== null) {
                console.log(xvalor);
                if (parseInt(valor_fill[0].cod_unidad_padre) <= 0) {
                  this.xtermino.des_gerencia = valor_fill[0].des_unidad;
                  let xobj = [];
                for (const i in response) {
                  if (parseInt(xvalor.value) ===  parseInt(response[i].cod_unidad_padre) ) {
                    xobj.push(response[i]);
                  }
                }
                  this.dpto = xobj;
                  await this.util.cerrarSpinner();
                }else{
                  this.xtermino.des_dpto = valor_fill[0].des_unidad;
                  this.xtermino.api = await this.hashkey.encrypt(await this.atrb.API_REST.listarRolesPerfil);
                  const perfil =  await this.servicio.ApiResthttp(this.xtermino).toPromise();
                  let xobj = [];
                  for (const i in perfil) {
                    xobj.push(perfil[i]);
                  }
                  this.cargo = xobj;
                  await this.util.cerrarSpinner();
                }
            }else{
              let xobj = [];
              if ((parseInt(this.rlsr) <= 1)) {
                for (const i in response) {
                  if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() === await this.atrb.GENERAL.toUpperCase()) {
                    xobj.push(response[i]);
                  }
                }
              }else{
                for (const i in response) {
                  if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
                    xobj.push(response[i]);
                  }
                }
              }
                this.gerencia = xobj;
                await this.util.cerrarSpinner();
            }  
      }else{
              console.log(xvalor,'valores change');
              let dpto;
              let cargo;
              await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
                const response = await this.servicio.listarUnidadhttp().toPromise();
                for (const i in response) {
                  response[i].cod_unidad = response[i].cod_unidad.toString();
                }
                this.cargo = [];
                this.estatus = await this.atrb.ESTATUS;
              if (xvalor !== null) {
                console.log(xvalor);
                if (parseInt(xvalor.value.cod_unidad_padre) <= 0) {
                  this.xtermino.des_gerencia = xvalor.value.des_unidad;
                  let xobj = [];
                for (const i in response) {
                  if (parseInt(xvalor.value.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
                    xobj.push(response[i]);
                  }
                }
                  this.dpto = xobj;
                  await this.util.cerrarSpinner();
                }else{
                  this.xtermino.des_dpto = xvalor.value.des_unidad;
                  const perfil = await this.servicio.listarRolesPerfilhttp(this.xtermino).toPromise();
                  let xobj = [];
                  for (const i in perfil) {
                    xobj.push(perfil[i]);
                  }
                  this.cargo = xobj;
                  await this.util.cerrarSpinner();
                }
            }else{
              let xobj = [];
              if ((parseInt(this.rlsr) <= 1)) {
                for (const i in response) {
                  if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() === await this.atrb.GENERAL.toUpperCase()) {
                    xobj.push(response[i]);
                  }
                }
              }else{
                for (const i in response) {
                  if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
                    xobj.push(response[i]);
                  }
                }
              }
                this.gerencia = xobj;
                await this.util.cerrarSpinner();
            }  
      }
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async listarUnidadUpdate(xvalor:any){
    try {
      let dpto;
      let cargo;
      await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        let xobjeto = {
          api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUnidades)
        }
        const response = await this.servicio.ApiResthttp(xobjeto).toPromise();
        for (const i in response) {
          response[i].cod_unidad = response[i].cod_unidad.toString();
        }
        this.cargo = [];
        this.estatus = await this.atrb.ESTATUS;
        console.log(xvalor,'terminos dpto');
          this.xtermino.des_gerencia = xvalor.des_unidad;
          let xobj_dpto = [];
        for (const i in response) {
          if (parseInt(xvalor.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
            xobj_dpto.push(response[i]);
          }
        }
          this.dpto = xobj_dpto;
        
          this.xtermino.des_dpto = xvalor.des_dpto;
          this.xtermino.api = await this.hashkey.encrypt(await this.atrb.API_REST.listarRolesPerfil);
          const perfil =  await this.servicio.ApiResthttp(this.xtermino).toPromise();
          let xobj_cargo = [];
          for (const i in perfil) {
            xobj_cargo.push(perfil[i]);
          }
          this.cargo = xobj_cargo;
      let xobj = [];
      if ((parseInt(this.rlsr) <= 1)) {
        for (const i in response) {
          if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() === await this.atrb.GENERAL.toUpperCase()) {
            xobj.push(response[i]);
          }
         }
      }else{
        for (const i in response) {
          if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
            xobj.push(response[i]);
          }
         }
      }
         this.gerencia = xobj;
         await this.util.cerrarSpinner();
    
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async cargar_img(xvalor:any){
    try {
      await this.util.abrirSpinner();
      this.objectURL = URL.createObjectURL(xvalor.target.files[0]);
     this.archivo = xvalor.target.files[0];
     
    await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
 async modal_respuesta(){
      try {
        await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
                await this.util.abrirSpinner();
                if(result != 'cerrar'){
                  if (parseInt(this.rlsr) > 1) {
                    this.forma.patchValue({
                      mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario)
                    });
                }
                if(this.data.accion.toLowerCase() === await this.atrb.ACCION_ACTUALIZAR.toLowerCase()){
                  const response = await this.servicio.listarUnidadhttp().toPromise();
                  for (const i in response) {
                    response[i].cod_unidad = response[i].cod_unidad.toString();
                  }
                  let unidad = this.forma.value.cod_unidad;
                  let dpto = this.forma.value.cod_dpto;
                  let rol = this.forma.value.cod_rol;
                  let ru = this.forma.value.cod_perfil;
                  let valor_unidad = response.filter( function(elem){
                    return elem.cod_unidad === unidad;
                  });
                  let valor_dpto = response.filter( function(elem){
                    return elem.cod_unidad === dpto;
                  });
                  let valor_rol = this.roles.filter( function(elem){
                    return elem.cod_usuario === rol;
                  });
                  const perfil = await this.servicio.listarRolesPerfilhttp(this.xtermino).toPromise();
                  let valor_perfil = perfil.filter( function(elem){
                    return elem.cod_rol === ru;
                  });
                  const formData = new FormData();
                  formData.append('user_img',this.archivo);
                  let pass_encritp = await this.hashkey.encrypt(this.forma.value.pass_usuario);
                  this.forma.patchValue({
                    des_rol: valor_rol[0].des_usuario,
                    cod_rol: valor_rol[0].cod_usuario,
                    des_dpto: valor_dpto[0].des_unidad,
                    cod_dpto: valor_dpto[0].cod_unidad,
                    des_perfil: valor_perfil[0].estructura_rol,
                    cod_perfil: valor_perfil[0].cod_rol,
                    des_unidad: valor_unidad[0].des_unidad,
                    cod_unidad: valor_unidad[0].cod_unidad,
                    cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
                    cod_app: await this.hashkey.encrypt(environment.path_token),
                    pass_usuario: pass_encritp,
                    formdata:formData
                  });
                  this.dialogRef.close(this.forma.value);
                }else{
                  const formData = new FormData();
                  formData.append('user_img',this.archivo);
                  let pass_encritp = await this.hashkey.encrypt(this.forma.value.pass_usuario);
                  this.forma.patchValue({
                    des_rol: this.forma.value.cod_rol.des_usuario,
                    cod_rol: this.forma.value.cod_rol.cod_usuario,
                    des_dpto: this.forma.value.cod_perfil.des_dpto,
                    cod_dpto: this.forma.value.cod_perfil.cod_dpto,
                    des_perfil: this.forma.value.cod_perfil.des_gerencia.toUpperCase()+this.forma.value.cod_perfil.des_dpto.toUpperCase()+this.forma.value.cod_perfil.des_rol.toUpperCase(),
                    cod_perfil: this.forma.value.cod_perfil.cod_rol,
                    des_unidad: this.forma.value.cod_unidad.des_unidad,
                    cod_unidad: this.forma.value.cod_unidad.cod_unidad.toString(),
                    cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
                    pass_usuario:pass_encritp,
                    cod_app: await this.hashkey.encrypt(environment.path_token),
                    formdata:formData
                  });
                  
                  this.dialogRef.close(this.forma.value);
                }
                }else{
                  await this.util.cerrarSpinner();
                }
        });
      } catch (error) {
        await this.notifi.customToastNotification(error.error.message);
      }   
  }

  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }
}
