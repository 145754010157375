import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtributosService {

  ////LOCAL/////
  ROL_USUARIOS:any = {cod_rol:localStorage.getItem('rlsr')} 
  COD_USUARIO:any =  {cod_usuario:localStorage.getItem('cod_usuario')};
  COD_EMPRESA:any =  {cod_empresa:localStorage.getItem('mprs')};
  NOMBRE_USUARIO:any = {nombre:localStorage.getItem('Nombre')};
  APELLIDO_USUARIO:any =  {apellido:localStorage.getItem('Apellido')};
  UNIDAD_USUARIO:any = {unidad:localStorage.getItem('Unidad')};
  DPTO_USUARIO:any = {dpto:localStorage.getItem('dpto')};
  SESION_USUARIO:any =  {cod_sesion:localStorage.getItem('cod_sesion')};
  IP_USUARIO:any = {dir_ip:localStorage.getItem('dir_ip') };
  SUB_MAIN:any = {sub_main:localStorage.getItem('sub_main')};
  MOD_USUARIO:any = {mod_usuario:localStorage.getItem('lcnc')};
  COD_PERFIL:any = {cod_perfil:localStorage.getItem('prfl')};
  FECHA:any = {yyyy:new Date().getFullYear()};
  ////LOCAL/////
  ////CONSTANTES/////
  APP:any = '0';
  DEF:any ='D';
  FUNC:any = 'F';
  OPER:any = 'O';
  TP_FUNC:any = 'TP';
  MENU_SISTEMA_GESTION:any = '55';
  ACCION_CREAR:any = 'crear';
  ACCION_BORRAR:any = 'borrar';
  ACCION_ACTUALIZAR:any = 'actualizar';
  CEO:any = '0';
  ADMINISTRADOR:any = '1';
  EMPRESA:any = '2';
  USUARIO:any = '3';
  TIEMPO_SPINNER:any = 1000;
  SIN_FORMULA:any = 'SNFRML';
  NO_APLICA:any = 'N/A';
  LOGO:any = 'logo';
  IMG:any = 'img';
  IMG_1:any = 'img_1';
  TXT:any = 'txt';
  TMP:any = 'tmp';
  FNC:any = 'fnc';
  PRC:any = 'prc';
  FNCP:any = 'fncp';
  PRCP:any = 'prcp';
  DATA:any = 'dt';
  IMG_D1:any = 'img_d1';
  CERRAR:any = 'cerrar';
  LOGIN:any = 'login';
  SALIR:any = 'salir';
  UNDEFINED:any = 'undefined';
  FACTURA:any = 'factura';
  nota_divisa:any = 'nota divisa';
  nota_bolivares:any = 'nota bolivares';
  FACTURA_T:any = 'f';
  PRO_FORMA:any = 'pre_forma';
  PRO_FORMA_T:any = 'pf';
  NOTA_ND:any = 'notaND';
  NOTA_ND_T:any = 'nd';
  NOTA_NB:any = 'notaNB';
  NOTA_NB_T:any = 'nb';
  POS:any = 'pos';
  UND:any = 'UND';
  UNDP:any = 'UNDP';
  UM:any = 'UM';
  GRS:any = 'GRS';
  SPCL:any = 'SPCL';
  CDN:any = 'CDN';
  PVP:any = 'PVP';
  DSTRBDR:any = 'DSTRBDR';
  CLNTPS:any = 'CLNTPS';
  PT:any = 'PT';
  PTE:any = 'PTE';
  PTO:any = 'PTO';
  INV:any = 'INV';
  CRNTN:any = 'CRNTN';
  COMPLETO:any = 'CMPLT';
  BASE:any = 'base';
  TASA:any = 'tasa';
  DESCUENTO:any = 'descuento';
  NUM:any = '1234567890';
  MONTO_0:any = '0,00';
  MONTO_00:any = '0';
  XCNT:any = 'XCNT';
  CIEN_PORCIENTO:any = '100,00';
  BOLIVARES:any = 'Bs';
  DOLARES:any = '$';
  BCV:any = 'BCV';
  IVA:any = 'IVA';
  LBE:any = 'LBE';
  DD:any = 'dd';
  CARGOS:any = 'cargo';
  DESCARGOS:any = 'descargo';
  CUARENTENA:any = 'crntn';
  CALC:any = 'calcular';
  NON:any = 'N/A';
  GENERAL:any = 'general';
  TOKEN:any = '2000';
  ACTIVO:any = 'A';
  INACTIVO:any = 'I';
  cantidad_unidad:any = '1';
  recuperar_pass:any = 'RP';
  UNIDAD_PADRE:any = 'UNIDAD PADRE';
  MENU_PADRE:any = 'MENU PADRE';
  ////CONSTANTES/////
  ////ACCESOS/////
  ACCESO_VALDEF:any = {
      almacen:'GSTNRLMCN',
      listas_precios:'GSTNRPRCS',
      gestionar_clientes:'GSTNRCLNTS',
      gestionar_productos:'GSTNRPRDCTS',
      gestionar_usuarios:'GSTNRSRS',
      gestionar_documentos:'GSTNRDCMNTS',
      gestionar_pedidos:'GSTNRPDDS',
      gestionar_empleados:'GSTNRMPLDS',
      gestionar_soporte:'GSTNRSPRT',
      gestionar_roles:'GSTNRRLS',
      gestionar_cargos:'GSTNRCRGS',
      gestionar_licencias:'GSTNRLCNCS',
      gestionar_definiciones:'GSTNRDFNCNS',
      gestionar_aplicaciones:'GSTNRPLCCNS',
      gestionar_smtp:'GSTNRSMTP'
  };
  ACCESO_OPERACION:any = {
    select:'SELECT',
    delete:'DELETE',
    update:'UPDATE',
    create:'INSERT',
    join:'JOIN',
    let_join:'LET_JOIN',
    inner_join:'INNER_JOIN',
    store_procedure:'STORE_PROCEDURE',
  };
  ////ACCESOS/////
  ////DEFINICIONES/////
  LICENCIAMIENTO: any = {
    tipos:'TIPOS DE LICENCIAS',
    parametros_licencia:'PARAMETROS DE LICENCIA',
  }
  DOCUMENTOS: any = {
    documentos:'DOCUMENTOS',
    parametros_documentos:'PARAMETROS DOCUMENTOS',
  }
  DEPOSITOS: any = {
    productos:'PRODUCTOS',
    depositos:'DEPOSITOS',
    tipo_de_manejo:'TIPO DE MANEJO',
  }
  PARAMETROS_VENTAS: any = {
    parametro_precio:'PARAMETROS VENTAS',
    opereaciones_definidas:'OPERACIONES PRECIOS',
    tipo_de_montos:'TIPO MONTOS PRECIOS',
  }
  PARAMETROS_ESTRUCTURA: any = {
    cargo:'CARGOS',
  }
  PRODUCTOS: any = {
    u_manejo:'UNIDAD DE MANEJO',
    u_medida:'UNIDAD DE MEDIDA',
    tipo_producto:'TIPO DE PRODUCTO',
  }
  PRODUCTOS_MP: any = {
    estatus_mp:'ESTATUS MP PRODUCTOS',
    producto_mp:'PRODUCTO MP',
    medidas_mp:'MEDIDAS PRODUCTO MP',
  }
  APLICACIONES: any = {
    aplicaciones:'APLICACIONES',
    parametros_aplicaciones:'PARAMETROS APLICACIONES',
    funcionalidad_aplicaciones:'FUNCIONALIDAD APLICACIONES',
    operacionalidad_aplicaciones:'OPERACIONALIDAD APLICACIONES',
    sub_operacionalidad_aplicaciones:'SUB-OPERACIONALIDAD APLICACIONES',
  }
  GALERIA_EMPRESA: any = {
    galeria:'GALERIA',
    parametros_galeria:'PARAMETROS GALERIA',
  }
  PRECIOS: any = {
    CLIENTES_PRECIOS:'CLIENTES PRECIOS',
  }
  ////DEFINICIONES/////
  ////CONSTANTES DOCUMENTOS/////
  CONSTANTES_APLICACIONES: any = {
    PRC_GLR:'PRC_GLR',
    PRC_PRCSS:'PRC_PRCSS',
    PRC_GLR_N:'PRC_GLR_N',
    PARAM_PRC_PRCS:'PARAM-PRC_PRCS',
    PARAM_2_PRC_PRCS:'PARAM_2-PRC_PRCS',
    PARAM_3_PRC_PRCS:'PARAM_3-PRC_PRCS',
    PARAM_4_PRC_PRCS:'PARAM_4-PRC_PRCS',
    PARAM_5_PRC_PRCS:'PARAM_5-PRC_PRCS',
    PARAM_6_PRC_PRCS:'PARAM_6-PRC_PRCS',
    TXT_TITULO:'TXT_TITULO',
    TXT_1:'TXT_1',
    TXT_2:'TXT_2',
    TXT_3:'TXT_3',
    IMG_LOGO:'IMG_LOGO',
    IMG_PIE:'IMG_PIE',
    IMG_ENC:'IMG_ENC',
    IMG_BAR_1:'IMG_BAR_1',
    IMG_BAR_2:'IMG_BAR_2',
    IMG_BAR_3:'IMG_BAR_3',
  }
  CONSTANTES_GALERIA: any = {
    IMG_LOGO:'IMG_LOGO',
    IMG_INICIO:'IMG_INICIO',
  }
  CONSTANTES_DOCUMENTOS: any = {
    DES_DOC:'DES_DOC',
    IMG_LOGO:'IMG_LOGO',
    DIR_LOG:'DIR_LOG',
    TEL_1:'TEL_1',
    TEL_2:'TEL_2',
    TEL_3:'TEL_3',
    MAIL_DOC:'MAIL_DOC',
    WEB_DOC:'WEB_DOC',
    RIF_DOC:'RIF_DOC',
    MSG_1:'MSG_1',
    MSG_2:'MSG_2',
    MSG_3:'MSG_3',
    MSG_4:'MSG_4',
    IMG_PIE:'IMG_PIE',
    POSTAL_DOC:'POSTAL_DOC',
    IMG_BAR:'IMG_BAR',
    FACTURA:'FACTURA',
    NOTA_ENTREGA_DIVISA:'NOTA ENTREGA DIVISA',
    NOTA_CREDITO:'NOTA CREDITO',
    NOTA_DEBITO:'NOTA DEBITO',
    NOTA_ENTREGA:'NOTA ENTREGA',
    NOTA_PRO_FORMA:'NOTA PRO FORMA',
    PRESUPUESTO_FACTURA:'PRESUPUESTO FACTURA',
    PRESUPUESTO_PRO_FORMA:'PRESUPUESTO PRO FORMA',
    PRESUPUESTO_NOTA:'PRESUPUESTO NOTA',
    PRESUPUESTO_NOTA_DIVISA:'PRESUPUESTO NOTA DIVISA',
    EXCEL:'EXCEL',
  }
  ////CONSTANTES DOCUMENTOS/////

  ////OPERACIONES/////
  OPERACIONES: any = {
    usuarios:'USUARIOS',
    pedidos:'PEDIDOS',
    depositos:'DEPOSITOS',
    precios:'PRECIOS',
    clientes:'CLIENTES',
    cliente_especial:'CLIENTE ESPECIAL',
    productos:'PRODUCTOS',
    proveedores:'PROVEEDORES',
    documentos:'DOCUMENTOS',
    empleados:'EMPLEADOS',
    soporte:'SOPORTE',
    aplicaciones:'APLICACIONES',
    smtp:'SMTP'
  }
  OPERACIONALES: any = {
    smtp:'SMTP',
    stripe : 'STRIPE',
    paypal : 'PAYPAL',
    membresia : 'MEMBRESIA',
    avatar : 'AVATAR',
    inicio : 'INICIO',
  }
  ////OPERACIONES/////
  ////RECUPERACION/////
  RECUPERACION: any = {
    recuperar_pass: 'recuperacion',
    correo_rep:'correo',
    codigo_rep:'codigo',
    pass_rep:'pass'
  }
  ////RECUPERACION/////
  ////MENSAJES FIJOS/////
  MENSAJES_FIJOS: any = {
    lista_actualizada:'Lista actualizada',
    seleccione_imagen:'Seleccione una imagen',
    agregar_texto:'Debe agregar texto en esta definición',
    agregar_valor:'Debe agregar un valor en esta definición',
    actualizar_img:'Por favor actulice la imagen',
    maximo_caracteres:'Sobrepasa el maximo de carateres permitidos',
    cliente_sin_um:'Este cliente no tiene unidad de manejo definida',
    registros_por_pagina:'Registros por pagina',
    cantidad_mayor_0:'La cantidad debe ser mayor a 0',
    descuento_0:'Para poder continuar el campo descuento debe estar en 0,00 %',
    precio_actualizado:'El precio de este producto fue actualizado.',
    tasa_base_0:'El campo Tasa Base se encuentra vacio o su cantidad es menor o igual a 0',
    solo_numeros:'Solo numeros por favor',
    precio_recalculado:'Los precios han sido recalculados',
    precio_almenos_producto:'Por lo menos un producto debe tener precio para realizar el calculo',
    prod_select_ya:'El producto ya fue seleccionado',
    solo_45:'Solo puede agregar un maximo de  45 items',
    prod_no_client:'Este producto no esta asignado a este cliente',
    prod_mayor_0:'La cantidad a solicitar debe ser mayor a 0',
    no_um:'No tiene unidad de manejo',
    unid_solicitar:'Debe indicar la cantidad de unidades a solicitar',
    prod_solicitar:'Debe seleccionar el producto',
    archi_vac:'El archivo se encuentra vacio',
    prod_0_up:'Tiene productos en 0',
    prod_no_asig:'Hay productos que no estan asiganados a este cliente, consulte su lista de precios actual',
    solo_file:'Debe seleccionar un solo archivo',
    sin_prod:'No haz seleccionado ningun producto',
    sin_iva:'El valor del IVA no esta definido',
    proceso_descargo:'Proceso de correción de inventario, debe estar autizado para realizar este proceso,\n se realiza un seguimiento del usuario que ejecuta este proceso para emitir reportes',
    rango_fecha:'Seleccione un rango de fecha',
    seguimiento_cargado:'Seguimiento cargado con exito',
    sin_seguimiento:'No existen movimientos del producto en el rango de fecha seleccionado',
    sin_registos:'No hay registros en la consulta actual',
    sin_parametros:'Para generar un documento en excel o reporte se deben definir sus parametros',
    general:'General',
    quitar_producto_lista:'El producto fue quitado de forma exitosa',
    precio_mayor_0:'El precio base debe ser mayor a 0',
    tasa_mayor_0:'La tasa debe ser mayor a 0',
    debe_seleccionar_prod:'Debe seleccionar un producto',
    ultimo_precio_actualizado:'Su lista de precios es la ultima actualización',
    lista_precio_no_actualizada:'Su lista de precios no esta actualizada',
    lista_precio_actualizada_manual:'Usted realizo la actualizacion de la lista de precios de forma exitosa',
    lista_precio_actualizada:'Ya usted realizo la actualizacion de la lista de precios',
    descarga_completa:'Descarga realizada con exito',
    titulo_excel:'Falta el titulo del documento',
    rif_excel:'Falta el rif del documento',
    img_excel:'Falta la imagen del documento',
    rft_excel:'Parametro inactivo',
    reporte_excel:'Reporte descargado con exito',
    seleccionar_consig:'Debe seleccionar la forma de consignación para el cliente tipo SPCL',
    peso_mayor_0:'El peso debe ser mayor a 0,00',
    tiempo_espera:'Tu sesion esta por cerrarse!',
    mayor_0_solo_num:'La cantidad debe ser mayor a 0 y solo se aceptan numeros',
    select_prod_base:'Debe seleccionar un producto base',
    quitar_item_lista:'Articulo eliminado con exito',
    agregar_item_lista:'Articulo agregado con exito',
    almacen_no_dispo_um_und:'No existe almacenes disponibles con el tipo de manejo seleccionado',
    ruta_menu_completa:'Por favor selecciona la ruta del menu  de forma completa',
    nada_en_lista:'No existe nada en su lista',
    parametros_funcionales:'Parametros funcionales',
    parametros_operacionales:'Parametros operacionales',
    img_avatar:'Imagen proceso avatar',
    escala_0:'Esta escala no tiene parametros definidos',
    img_cargada:'Imagen cargada con exito',
    param_titulo:'Defina el parametro titulo',
  }
  ////MENSAJES FIJOS/////
  ////IMAGENES FIJAS/////
  IMAGENES_FIJAS: any = {
    sin_img:'../../../assets/img/blank_.png',
    sin_img_prod:'../../../assets/img/drop-images.png',
  }
  ////IMAGENES FIJAS/////
  ////PARAMETROS FORMULARIO MENSAJES/////
  PARAMETROS_MSM: any = {
    crear_documentos:'crear_documentos',
    crear_app:'crear_app',
  }
  ////PARAMETROS FORMULARIO MENSAJES/////
  ////ARRAYS DATA TABLE COMOPONENTES/////
  DEPOSITOS_ARRAY: any = ['cod_deposito','des_deposito','descrip_deposito','tipo_deposito','tipo_manejo','estatus','fecha','hora','inventario'];
  FORM_DEP_UM_PT: any = ['cod_img','cod_producto','des_producto','tipo','unidad_manejo','cant_producto','um_producto','procesos'];
  FORM_DEP_UM_D_PT: any = ['cod_img','cod_producto','des_producto','tipo','unidad_manejo','cant_producto','um_producto','procesos'];
  FORM_DEP_D_UM_PT: any = ['cod_img','cod_producto','des_producto','tipo','cant_producto','procesos'];
  FORM_DEP_D_UM_D_PT: any = ['cod_img','cod_producto','des_producto','tipo','cant_producto','procesos'];
  TABLE_CONSULTA_PEDIDOS: any = ['cod_pedido','cod_lista','cod_cliente','des_cliente','tipo_pedido','fecha_inicio','estatus_pedido','procesos'];
  TABLE_PARAMETROS_OPER_VENTAS_FALSE: any = ['cod_definicion','des_definicion','descrip_definicion','val_def','tipo_monto','operacion',
                                             'estatus','fecha_inicio','hora_inicio','procesos'];
  TABLE_PARAMETROS_OPER_VENTAS_TRUE: any = ['cod_definicion','des_definicion','descrip_definicion','val_def','tipo_monto','operacion',
                                            'estatus','fecha_inicio','hora_inicio','update','definiciones','tiempo'];
  TABLA_USUARIOS: any = ['cod_img','cod_usuario','Nombre','Apellido','cod_unidad','des_unidad','Nombre_de_usuario','licencia','Estatus','procesos'];
  TABLA_USUARIOS_ADMIN: any = ['cod_img','cod_usuario','Nombre','Apellido','cod_unidad','des_unidad','Nombre_de_usuario','licencia','Estatus','procesos'];
  TABLA_GESTION_APP: any = ['cod_app','des_app','comentarios','cod_empresa','estatus','fecha_inicio','hora_inicio','procesos'];

  TABLA_PROCESOS = {
  TABLA_FORM_PARAMETROS_APP:['cod_app','cod_empresa','definicion_app','val_def','procesos'],
  TABLA_FORM_PARAMETROS_APP_F:['id_relacion_definicion_app','cod_app','definicion_app','val_def','tipo_cat','procesos'],
  TABLA_FORM_PARAMETROS_APP_O:['cod_app','definicion_app','tipo_cat','procesos'],
  TABLA_FORM_PARAMETROS_GALERIA:['cod_empresa','definicion_app','val_def','fecha_inicio','hora_inicio','procesos'],
  TABLA_FORM_PARAMETROS_INICIO:['cod_param_1','val_def','cod_img','des_inicio','estatus','fecha_inicio','hora_inicio','procesos'],
  TABLA_FORM_PARAMETROS_1:['cod_param_1','val_def','cod_img','des_inicio','estatus','fecha_inicio','hora_inicio','procesos'],
  TABLA_FORM_PARAMETROS_2:['cod_param_1','val_def','des_inicio','estatus','fecha_inicio','hora_inicio','procesos'],
  }
  TABLA_PRINCIPAL = {
    TABLA_MENU:['cod_menu','des_menu','dir_menu','estatus_menu','ord_menu','padre_menu','des_padre_menu','nivel_padre_menu',
                'cod_unidad','des_unidad','procesos'],
    TABLA_UNIDADES:['cod_unidad','des_unidad','estatus','respon_unidad','cod_unidad_padre','des_unidad_padre','procesos'],
    TABLA_LOG:['id_seg_system_logs','des_controlador','des_metodo','cod_error','des_error','fecha_ini','hora_ini'],
    TABLA_SMTP:['cod_smtp','des_smtp','estatus','host_smtp','user_smtp','pass_smtp','ssl_smtp','port_smtp','cod_empresa','procesos'],
    TABLA_STR_EMPRESA:['cod_estructura','des_gerencia','des_dpto','des_cargo','estatus','fecha_inicio','hora_inicio'],
    TABLA_LICENCIAS:['cod_licencia','des_licencia','descrip_licencia','estatus','fecha_inicio','hora_inicio','procesos'],
  }
  ////ARRAYS DATA TABLE COMOPONENTES/////
  ////GALERIA/////
  GALERIA:any = {
    app_img:'app_img'
  }
  ////GALERIA/////
  TIPO_PRODUCTO:any = {
    PT:'PT',
    PTE:'PTE',
    PTO:'PTO',
    MPP:'MPP',
    MP:'MP',
    ME:'ME',
    MT:'MT',
    EM:'EM'
  }
  ////TIPOS DE PRODUCTOS/////
  ////API REST/////
  ENCAB:any = {
    header_1:new HttpHeaders().set('Content-Type','application/json')
  }
  API_REST:any = {
    gestionAplicaciones:environment.path_prod+'gestionAplicaciones',
    listarAplicaciones:environment.path_prod+'listarAplicaciones',
    listarParametroAplicaciones:environment.path_prod+'listarParametroAplicaciones',
    gestionParametroAplicaciones:environment.path_prod+'gestionParametroAplicaciones',
    gestionarImgUser:environment.path_prod+'gestionarImgUser',
    listarUnidades:environment.path_prod+'listarUnidades',
    gestionunidades:environment.path_prod+'gestionunidades',
    gestionmenu:environment.path_prod+'gestionmenu',
    listarLogs:environment.path_prod+'listarLogs',
    validar_acceso_acciones_http:environment.path_prod+'validar_acceso_acciones_http',
    listarSmtp:environment.path_prod+'listarSmtp',
    gestionSmtp:environment.path_prod+'gestionSmtp',
    listarUsuarios:environment.path_prod+'listarUsuarios',
    gestionRecuperacion:environment.path_prod+'gestionRecuperacion',
    validarCodigoRecu:environment.path_prod+'validarCodigoRecu',
    cambiarContrasena:environment.path_prod+'cambiarContrasena',
    consultarUsuarios:environment.path_prod+'consultarUsuarios',
    buscardefiniciones:environment.path_prod+'buscardefiniciones',
    listarParametroGaleria:environment.path_prod+'listarParametroGaleria',
    gestionParametroGaleria:environment.path_prod+'gestionParametroGaleria',
    listarLicencias:environment.path_prod+'listarLicencias',
    validarLicenciaProcesos:environment.path_prod+'validarLicenciaProcesos',
    ingresoUsuario:environment.path_prod+'ingresoUsuario',
    listarEstructuraOrganizacion:environment.path_prod+'listarEstructuraOrganizacion',
    gestionOrganizacion:environment.path_prod+'gestionOrganizacion',
    gestionLicencias:environment.path_prod+'gestionLicencias',
    listarRolesPerfil:environment.path_prod+'listarRolesPerfil',
    listarParametroAplicacionesGaleria:environment.path_prod+'listarParametroAplicacionesGaleria',
    listarParametroAppInicio:environment.path_prod+'listarParametroAppInicio',
    editarNombreArchivosHttp:environment.path_prod+'editarNombreArchivosHttp',
  }
  ////API REST/////
  ////TIPOS DE PEDIDO/////
  TIPO_PEDIDO:any = {
              F:'F',
              PSF:'PSF',
              ND:'ND',
              PSND:'PSND',
              NB:'NB',
              PSNB:'PSNB',
              PF:'PF',
              PSPF:'PSPF'
  }
  ////TIPOS DE PEDIDO/////
  ////TIPOS DE PROCESO MSM/////
  TIPO_PROCESO:any = {
    crear_estructura:'crear_estructura',
  }
  ////TIPOS DE PROCESO MSM/////
  ////ESTATUS/////
  ESTATUS:any = [
    {
      cod_estatus:'A',
      des_estatus:'Activo',
    },
    {
      cod_estatus:'I',
      des_estatus:'Inactivo'
    }
  ] 
  ////NIVEL MENU/////
  NIVEL_MENU:any = [
    {
      cod_nivel:'0',
      des_nivel:'Menu padre',
    },
    {
      cod_nivel:'1',
      des_nivel:'Menu hijo'
    },
    {
      cod_nivel:'N/A',
      des_nivel:'No aplica'
    },
  ] 
  ////ESTATUS FISCAL////
  ESTATUS_FISCAL:any = [
    {
      cod_estatus:'XCNT',
      des_estatus:'EXCENTO',
    },
    {
      cod_estatus:'NXCNT',
      des_estatus:'NO EXCENTO'
    }
  ] 
  ////ESTATUS////
  ////EXPRESIONES REGULARES////
  EMAILREGEX:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  TELREGEX:any = /\d{4}-\d{6}\d/;
  CODREGEX:any = /^[J,V,E,G]{1}\d{9}/;
  ////EXPRESIONES REGULARES////
  ////FORMAS PARA LA VISTA////
  PEDIDOS_FORMA:any = {
    facturas:'FACTURA',
    presupuesto_f:'PRESUPUESTO BS',
    presupuesto_nd:'PRESUPUESTO $',
  }
  ////FORMAS PARA LA VISTA////
  ////ESTATUS////
  ////GET&SET/////
  private media_query: any;
  ////GET&SET/////

  constructor() { }

  public get getMedia(): any {
    return this.media_query;
  }
  public set setMedia(value: any) {
    this.media_query = value;
  }
}
